<template>
  <div>
    <!--    <commingSoon />-->
    <b-container>
      <b-card class="card-about">
        <h2
          class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5"
        >
          {{ $t("endUser.termsCondition") }}
        </h2>
        <p class="text-justify font-size-20 text-black px-4" v-html="terms"></p>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "termsCondition",
  computed: {
    terms() {
      return this.$store.getters["Setting/appSettings"]["terms"];
    },
  },
};
</script>
<style lang="scss">
.card-about {
  margin-top: 3rem;
  padding: 3rem;
  text-align: center;
}
</style>
